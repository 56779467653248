import React, { useState, useEffect, useCallback } from 'react'
import questions from 'application/data/questions.json'
import { ReactComponent as FooterSvg } from 'images/footer_img.svg'
import { Answer, Questions } from 'application/domain/entity/question/Question'
import { useNavigate } from 'react-router'
import _sampleSize from 'lodash/sampleSize'

import s from './QuizPage.module.scss'
import Header from './components/Header'
import Scores from './components/Scores'
import QuestionsList from './components/QuestionsList'

const QuizPage = () => {
  const navigate = useNavigate()
  const [answersData, setAnswersData] = useState<{
    questionNum: number
    scores: number
  }>(() => {
    const a = localStorage.getItem('answersData')
    if (a) {
      return JSON.parse(a)
    }
    return {
      scores: 0,
      questionNum: 0,
    }
  })
  const [questionsData, setQuestionsData] = useState<Questions>(() => {
    const q = localStorage.getItem('questions')
    if (q) {
      return JSON.parse(q)
    } else {
      let randomSample = _sampleSize(questions, 16)
      localStorage.setItem('questions', JSON.stringify(randomSample))
      return randomSample
    }
  })

  const onAnswerClick = useCallback((item: Answer) => {
    setAnswersData((prev) => {
      const newData = {
        questionNum: prev.questionNum + 1,
        scores: item && item.correct ? prev.scores + 1 : prev.scores,
      }
      localStorage.setItem('answersData', JSON.stringify(newData))
      return newData
    })
  }, [])

  useEffect(() => {
    if (answersData.questionNum === 16) {
      navigate(`/results?scores=${answersData.scores}`)
    }
  }, [answersData, navigate])

  return (
    <div className={s.container}>
      <Header questionNum={answersData.questionNum} />
      <QuestionsList data={questionsData} onAnswerClick={onAnswerClick} answersData={answersData} />

      <Scores scores={answersData.scores} />
      <div className={s.footer}>
        <div className={s.inner}>
          <FooterSvg />
          <FooterSvg />
          <FooterSvg />
        </div>
      </div>
    </div>
  )
}

export default React.memo(QuizPage)
