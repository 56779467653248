import React, { useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectFade, Autoplay } from 'swiper/modules'
import { ReactComponent as ArrowSvg } from 'images/right_arrow.svg'
import { NavLink } from 'react-router-dom'
import { ReactComponent as LogoSvg } from 'images/logo.svg'

import s from './MainPage.module.scss'
import Slide1 from './slides/Slide1'
import Slide2 from './slides/Slide2'
import Slide3 from './slides/Slide3'
import Slide4 from './slides/Slide4'
import Slide5 from './slides/Slide5'
import Slide6 from './slides/Slide6'
import Slide7 from './slides/Slide7'
import Slide8 from './slides/Slide8'
import 'swiper/css/autoplay'

function getRandomInt(max: number) {
  return Math.floor(Math.random() * max)
}

const MainPage = () => {
  useEffect(() => {
    localStorage.removeItem('questions')
    localStorage.removeItem('answersData')
  }, [])

  return (
    <div className={s.out_container}>
      <Swiper
        className={s.swiper}
        modules={[Autoplay]}
        speed={800}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        initialSlide={getRandomInt(8)}
        loop
        allowTouchMove={false}
      >
        <SwiperSlide className={s.slide}>
          <Slide1 />
        </SwiperSlide>
        <SwiperSlide>
          <Slide2 />
        </SwiperSlide>
        <SwiperSlide>
          <Slide3 />
        </SwiperSlide>
        <SwiperSlide>
          <Slide4 />
        </SwiperSlide>
        <SwiperSlide>
          <Slide5 />
        </SwiperSlide>
        <SwiperSlide>
          <Slide6 />
        </SwiperSlide>
        <SwiperSlide>
          <Slide7 />
        </SwiperSlide>
        <SwiperSlide>
          <Slide8 />
        </SwiperSlide>
      </Swiper>
      <div className={s.content_container}>
        <div className={s.content}>
          <div className={s.logo}>
            <LogoSvg />
          </div>
          <div className={s.title}>
            Викторина
            <br />
            «Что вы знаете
            <br />о Чувашии?»
          </div>
          {/* <div className={s.subtitle}>Пройди викторину на экспозиции Чувашии и получи сувенир</div> */}
          <NavLink className={s.button} to="/quiz">
            <div className={s.text}>Начать</div>
            <div className={s.arrow}>
              <ArrowSvg />
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  )
}

export default React.memo(MainPage)
