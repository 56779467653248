const setupElements = function (el: any) {
  const containerEl = document.createElement('div')
  const elPosition = el.style.position

  if (elPosition !== 'relative' || elPosition !== 'absolute') {
    el.style.position = 'relative'
  }

  containerEl.classList.add('confetti-container')

  el.appendChild(containerEl)

  return containerEl
}

const renderConfetti = function (
  el: any,
  containerEl: any,
  confettiColors: any,
  confettiAnimations: any,
) {
  const confettiInterval = setInterval(() => {
    const confettiEl: any = document.createElement('div')
    const confettiSize = Math.floor(Math.random() * 3) + 7 + 'px'
    const confettiBackground = confettiColors[Math.floor(Math.random() * confettiColors.length)]
    const confettiLeft = Math.floor(Math.random() * el.offsetWidth) + 'px'
    const confettiAnimation =
      confettiAnimations[Math.floor(Math.random() * confettiAnimations.length)]

    confettiEl.classList.add('confetti', 'confetti--animation-' + confettiAnimation)
    confettiEl.style.left = confettiLeft
    confettiEl.style.width = confettiSize
    confettiEl.style.height = confettiSize
    confettiEl.style.backgroundColor = confettiBackground

    confettiEl.removeTimeout = setTimeout(function () {
      confettiEl.parentNode.removeChild(confettiEl)
    }, 3000)

    containerEl.appendChild(confettiEl)
  }, 25)
}

export const Confettiful = function (el: any) {
  const confettiFrequency = 3
  const confettiColors = ['#fce18a', '#ff726d', '#b48def', '#f4306d']
  const confettiAnimations = ['slow', 'medium', 'fast']

  const containerEl = setupElements(el)
  renderConfetti(el, containerEl, confettiColors, confettiAnimations)
}
