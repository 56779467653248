import React, { useEffect, useState } from 'react'
import { Question as QuestionType } from 'application/domain/entity/question/Question'
import cn from 'classnames'

import s from './Question.module.scss'

type Props = {
  data: QuestionType
  needShow: boolean
}

const Question = ({ data, needShow }: Props) => {
  const [show, setShow] = useState<boolean>(false)
  useEffect(() => {
    if (needShow) {
      setTimeout(() => setShow(true), 300)
    }
  }, [needShow])
  return (
    <div className={s.container}>
      <div className={s.question}>
        <div className={cn(s.title, { [s.show]: show })}>{data.title}</div>
      </div>
    </div>
  )
}

export default React.memo(Question)
