import AnswerImage1 from 'images/answerImages/answer_1.jpg'
import AnswerImage2 from 'images/answerImages/answer_2.jpg'
import AnswerImage3 from 'images/answerImages/answer_3.jpg'
import AnswerImage4 from 'images/answerImages/answer_4.jpg'
import AnswerImage5 from 'images/answerImages/answer_5.jpg'
import AnswerImage6 from 'images/answerImages/answer_6.jpg'
import AnswerImage7 from 'images/answerImages/answer_7.jpg'
import AnswerImage8 from 'images/answerImages/answer_8.jpg'
import AnswerImage9 from 'images/answerImages/answer_9.jpg'
import AnswerImage10 from 'images/answerImages/answer_10.jpg'
import AnswerImage11 from 'images/answerImages/answer_11.jpg'
import AnswerImage12 from 'images/answerImages/answer_12.png'
import AnswerImage13 from 'images/answerImages/answer_13.jpg'
import AnswerImage14 from 'images/answerImages/answer_14.jpg'
import AnswerImage15 from 'images/answerImages/answer_15.jpg'
import AnswerImage16 from 'images/answerImages/answer_16.jpg'
import AnswerImage17 from 'images/answerImages/answer_17.jpg'
import AnswerImage18 from 'images/answerImages/answer_18.jpg'
import AnswerImage19 from 'images/answerImages/answer_19.jpg'
import AnswerImage20 from 'images/answerImages/answer_20.jpg'
import AnswerImage21 from 'images/answerImages/answer_21.jpg'
import AnswerImage22 from 'images/answerImages/answer_22.jpg'
import AnswerImage23 from 'images/answerImages/answer_23.jpg'
import AnswerImage24 from 'images/answerImages/answer_24.jpg'
import AnswerImage25 from 'images/answerImages/answer_25.jpg'
import AnswerImage26 from 'images/answerImages/answer_26.jpg'
import AnswerImage27 from 'images/answerImages/answer_27.jpg'
import AnswerImage28 from 'images/answerImages/answer_28.jpg'
import AnswerImage29 from 'images/answerImages/answer_29.jpg'
import AnswerImage30 from 'images/answerImages/answer_30.jpg'
import AnswerImage31 from 'images/answerImages/answer_31.jpg'
import AnswerImage32 from 'images/answerImages/answer_32.jpg'
import AnswerImage33 from 'images/answerImages/answer_33.jpg'
import AnswerImage34 from 'images/answerImages/answer_34.png'
import AnswerImage35 from 'images/answerImages/answer_35.jpg'
import AnswerImage36 from 'images/answerImages/answer_36.jpg'
import AnswerImage37 from 'images/answerImages/answer_37.jpg'
import AnswerImage38 from 'images/answerImages/answer_38.jpg'
import AnswerImage39 from 'images/answerImages/answer_39.jpg'
import AnswerImage40 from 'images/answerImages/answer_40.jpg'

export default [
  AnswerImage1,
  AnswerImage2,
  AnswerImage3,
  AnswerImage4,
  AnswerImage5,
  AnswerImage6,
  AnswerImage7,
  AnswerImage8,
  AnswerImage9,
  AnswerImage10,
  AnswerImage11,
  AnswerImage12,
  AnswerImage13,
  AnswerImage14,
  AnswerImage15,
  AnswerImage16,
  AnswerImage17,
  AnswerImage18,
  AnswerImage19,
  AnswerImage20,
  AnswerImage21,
  AnswerImage22,
  AnswerImage23,
  AnswerImage24,
  AnswerImage25,
  AnswerImage26,
  AnswerImage27,
  AnswerImage28,
  AnswerImage29,
  AnswerImage30,
  AnswerImage31,
  AnswerImage32,
  AnswerImage33,
  AnswerImage34,
  AnswerImage35,
  AnswerImage36,
  AnswerImage37,
  AnswerImage38,
  AnswerImage39,
  AnswerImage40,
]
