import React, { useCallback, useState, useRef, useEffect, useMemo } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css/effect-cards'
import { EffectCards, EffectFade, Navigation, Thumbs } from 'swiper/modules'
import { Answer, Question as QuestionType } from 'application/domain/entity/question/Question'
import SwiperType from 'swiper'
import { useNavigate } from 'react-router'

import s from './QuestionsList.module.scss'
import Question from './Question'
import Answers from './Answers'
import AnswerModal from './AnswerModal'

type Props = {
  data: Array<QuestionType>
  onAnswerClick: (item: Answer) => void
  answersData: {
    scores: number
    questionNum: number
  }
}

const QuestionsList = ({ data, onAnswerClick, answersData }: Props) => {
  const navigate = useNavigate()
  const timer = useRef<any>()
  const [questionNum, setQuestionNum] = useState<number>(1)
  const [seconds, setSeconds] = useState<number>(40)
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null)
  const [swiperObj, setSwiperObj] = useState<any>(null)
  const [mwData, setMwData] = useState<{ opened: boolean; data: any }>({
    opened: false,
    data: null,
  })

  const onSlideChange = useCallback((swiper: SwiperType) => {
    setQuestionNum(swiper.activeIndex + 1)
  }, [])

  const question = useMemo(() => data[questionNum - 1], [questionNum, data])

  const startTimer = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current)
    }
    timer.current = setTimeout(() => {
      if (seconds > 0) {
        setSeconds((prev) => prev - 1)
      } else {
        localStorage.setItem('answersData', JSON.stringify(answersData))
        navigate('/results?time_end=true')
      }
    }, 1000)
  }, [answersData, navigate, seconds])

  const onModalClose = useCallback(
    (item: Answer) => {
      swiperObj.slideNext()
      setSeconds(40)
      startTimer()
      return onAnswerClick(item)
    },
    [onAnswerClick, swiperObj, startTimer],
  )

  const onAnswerClickHandler = useCallback(
    (item: Answer) => {
      setMwData({ opened: true, data: { ...item, question: question } })

      // swiperObj.slideNext()
      // return onAnswerClick(item)
    },
    [question],
  )

  // useEffect(() => {
  //   if (questionNum) {
  //     setSeconds(20)
  //     startTimer()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [questionNum])

  useEffect(() => {
    startTimer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds])

  return (
    <>
      <div className={s.container}>
        <div className={s.number}>Вопрос {questionNum}/16</div>
        <div className={s.timer}>{seconds}</div>
        <Swiper
          effect={'cards'}
          navigation={true}
          modules={[EffectCards, Thumbs]}
          className={s.swiper}
          speed={800}
          // onSlideChange={onSlideChange}
          onSlideChangeTransitionStart={onSlideChange}
          onSwiper={setSwiperObj}
          noSwiping
          allowTouchMove={false}
          initialSlide={answersData.questionNum}
        >
          {data.map((item, index: number) => (
            <SwiperSlide className={s.slide} key={`question_item_${item.id}`}>
              <Question data={item} needShow={answersData.questionNum >= index} />
            </SwiperSlide>
          ))}
        </Swiper>
        <Answers
          data={question}
          onAnswerClick={onAnswerClickHandler}
          setSeconds={setSeconds}
          timerRef={timer}
        />
      </div>
      <AnswerModal
        mwData={mwData}
        onModalClose={onModalClose}
        setMwData={setMwData}
        question={question}
      />
    </>
  )
}

export default React.memo(QuestionsList)
