import React from 'react'
import { Route, Routes } from 'react-router'

import MainPage from '../pages/MainPage'
import QuizPage from '../pages/QuizPage/QuizPage'
import ResultPage from '../pages/ResultPage/ResultPage'

const RootNavigation = () => {
  return (
    <Routes>
      <Route element={<MainPage />} path="/" />
      <Route element={<QuizPage />} path="/quiz" />
      <Route element={<ResultPage />} path="/results" />
    </Routes>
  )
}

export default React.memo(RootNavigation)
