import React, { useCallback, useRef, useEffect, useState, useMemo } from 'react'
import cn from 'classnames'
import { ReactComponent as CircleSvg } from 'images/circle_logo.svg'
import { Button } from 'application/presentation/common/uiComponents'
import { Answer } from 'application/domain/entity/question/Question'
import _find from 'lodash/find'
import answerImages from 'application/data/answerImages'

import s from './AnswerModal.module.scss'

type Props = {
  mwData: {
    opened: boolean
    data: any
  }
  onModalClose: (item: Answer) => void
  setMwData: any
  question: any
}

const AnswerModal = ({ mwData, onModalClose, setMwData, question }: Props) => {
  const timer = useRef<any>()
  const [seconds, setSeconds] = useState<number>(20)
  const [questionData, setQuestionData] = useState<any>(question)
  const onClose = useCallback(() => {
    setMwData((prev: any) => ({ ...prev, opened: false }))
    if (timer.current) {
      clearTimeout(timer.current)
    }
    return onModalClose(mwData.data)
  }, [mwData, onModalClose, setMwData])

  const startTimer = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current)
    }
    if (mwData.opened) {
      timer.current = setTimeout(() => {
        console.log('modalTimer')
        if (seconds > 0) {
          setSeconds((prev) => prev - 1)
        } else {
          onClose()
        }
      }, 1000)
    }
  }, [onClose, seconds, mwData])

  const image = useMemo(() => answerImages[question.id - 1], [question])

  useEffect(() => {
    if (mwData.opened) {
      setSeconds(20)
      startTimer()
    } else {
      if (timer.current) {
        clearTimeout(timer.current)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mwData.opened])

  useEffect(() => {
    setTimeout(() => setQuestionData(question), 500)
  }, [question])

  useEffect(() => {
    startTimer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds])

  return (
    <div className={cn(s.container, { [s.opened]: mwData.opened })}>
      <div className={cn(s.background_circle, s.rotating)}>
        <CircleSvg />
      </div>
      <div className={s.inner_container}>
        <div className={s.progress_line}>
          <div className={s.progress} style={{ width: `${100 - (seconds / 20) * 100}%` }}></div>
        </div>
        <div className={s.title}>
          {mwData.data && mwData.data.correct ? (
            <>Молодец, ты ответил верно!</>
          ) : (
            <>
              Не переживай, в следующий раз ты
              <br />
              дашь верный ответ!
            </>
          )}
        </div>
        <div className={s.hint}>Правильный ответ</div>
        {questionData ? (
          <>
            <div className={s.answer_title}>
              {_find(questionData.answers, { correct: true })?.title}
            </div>
            <div className={s.answer_subtitle}>{questionData.title}</div>
            <div className={s.img}>
              <img src={image} />
            </div>
          </>
        ) : (
          <></>
        )}

        <Button
          color="red"
          text="Далее"
          withArrow
          containerClassName={s.btn_container}
          onClick={onClose}
        />
      </div>
    </div>
  )
}

export default React.memo(AnswerModal)
