import React, { useMemo } from 'react'
import { useLocation } from 'react-router'
import { ReactComponent as CircleSvg } from 'images/circle_logo.svg'

import s from './ResultPage.module.scss'
import TimerEndStage from './stages/TimerEndStage'
import SuccessContainer from './stages/SuccessContainer'
// import FailContainer from './stages/FailContainer'

const ResultPage = () => {
  const { search } = useLocation()

  const stage = useMemo(() => {
    const params = new URLSearchParams(search)
    const endTimer = params.get('time_end')
    if (endTimer) {
      return 1
    }
    const scores = params.get('scores')
    if (scores) {
      const scoreNum = parseInt(scores, 10)
      // if (scoreNum === 16) {
      //   return 2
      // }
      return 2
    }
    return 0
  }, [search])

  return (
    <div className={s.container}>
      <div className={s.background_circle}>
        <CircleSvg />
      </div>
      <div className={s.inner_container}>
        {stage === 1 && <TimerEndStage />}
        {stage === 2 && <SuccessContainer />}
        {/* {stage === 3 && <FailContainer />} */}
      </div>
    </div>
  )
}

export default React.memo(ResultPage)
