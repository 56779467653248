import React from 'react'
import { ReactComponent as CircleSvg } from 'images/circle_logo.svg'
import backgroundImg from 'images/backgrounds/background_6.png'

import s from '../MainPage.module.scss'

const Slide2 = () => {
  return (
    <div className={s.container} style={{ background: 'transparent' }}>
      <img src={backgroundImg} className={s.background} style={{ opacity: 1 }} />
      <div className={s.shadowbox}></div>
      <div className={s.background_circle}>
        <CircleSvg />
      </div>
    </div>
  )
}

export default React.memo(Slide2)
