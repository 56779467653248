import React from 'react'
import { ReactComponent as HeartSvg } from 'images/heart.svg'
import { CircleProgressBar } from 'application/presentation/common/uiComponents'

import s from './Scores.module.scss'

type Props = {
  scores: number
}

const Scores = ({ scores }: Props) => {
  return (
    <div className={s.container}>
      <div className={s.heart}>
        <HeartSvg />
      </div>
      <div className={s.scores}>
        <CircleProgressBar
          color="#A30800"
          backgroundColor="#fff"
          procent={(scores / 16) * 100}
          size={'small'}
          circleContentClassName={s.circle_content}
        >
          <div className={s.count}>{scores}</div>
        </CircleProgressBar>
      </div>
    </div>
  )
}

export default React.memo(Scores)
