import React, { useMemo, useEffect, useCallback } from 'react'
import { ReactComponent as HeartSvg } from 'images/heart.svg'
import { useLocation, useNavigate } from 'react-router'
import webpIcon from 'images/animated/512.webp'
import gifIcon from 'images/animated/512.gif'
import { Button } from 'application/presentation/common/uiComponents'

import { useTimer } from '../useTimer'
import GoBackBtn from '../components/GoBackBtn'
import s from '../ResultPage.module.scss'

import './confeti.scss'
import './confeti'
import { Confettiful } from './confeti'

const SuccessContainer = () => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const { seconds } = useTimer(180)
  const scores = useMemo(() => {
    const params = new URLSearchParams(search)
    const scoreNum = params.get('scores')
    return scoreNum || '0'
  }, [search])

  const repeatBtnClick = useCallback(() => {
    localStorage.removeItem('questions')
    localStorage.removeItem('answersData')
    navigate(-1)
  }, [navigate])

  useEffect(() => {
    //@ts-ignore
    const c: any = Confettiful(document.querySelector('.js-container'))
  }, [])

  return (
    <>
      <div className="js-container container"></div>
      <GoBackBtn seconds={seconds} />
      <div className={s.stage_container}>
        <div className={s.heart}>
          <HeartSvg />
        </div>
        <div className={s.title}>
          Вау!
          <br />
          Правильных ответов <span style={{ color: '#5BA300' }}>{scores}</span>
          <br />
          Ты молодец!
          <picture className={s.picture}>
            <source srcSet={webpIcon} type="image/webp" />
            <img src={gifIcon} alt="🥳" width="32" height="32" />
          </picture>
        </div>
        {/* <div className={s.subtitle}>
          Позовите сотрудника стенда, чтобы получить
          <br />
          сувенир из Чувашии
        </div> */}
        <Button
          text="НАЧАТЬ ЗАНОВО"
          color="red"
          containerClassName={s.repeat_btn}
          onClick={repeatBtnClick}
          withRepeatIcon
        />
      </div>
    </>
  )
}

export default React.memo(SuccessContainer)
