import React from 'react'
import { ReactComponent as CircleSvg } from 'images/circle_logo.svg'
import backgroundImg from 'images/background_mother.png'

import s from '../MainPage.module.scss'

const Slide1 = () => {
  return (
    <div className={s.container}>
      <img src={backgroundImg} className={s.background} />
      <div className={s.background_circle}>
        <CircleSvg />
      </div>
    </div>
  )
}

export default React.memo(Slide1)
