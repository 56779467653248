import React from 'react'
import { ReactComponent as BackSvg } from 'images/back_arrow.svg'
import { ReactComponent as Ornament } from 'images/ornament.svg'
import { useNavigate } from 'react-router'
import cn from 'classnames'

import s from './Header.module.scss'

type Props = {
  questionNum: number
}

const Header = ({ questionNum }: Props) => {
  const navigate = useNavigate()
  return (
    <div className={s.container}>
      <div className={s.left} onClick={() => navigate(-1)}>
        <BackSvg />
      </div>
      <div className={s.ornament}>
        <Ornament />
      </div>
      <div className={s.title}>ВИКТОРИНА О ЧУВАШИИ</div>
      <div className={cn(s.ornament, s.second_ornament)}>
        <Ornament />
      </div>
      <div className={s.right}>{questionNum + 1}/16</div>
    </div>
  )
}
export default React.memo(Header)
