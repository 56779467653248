import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import RootNavigation from 'application/presentation/navigation/RootNavigation'

import s from './AppView.module.scss'

const AppView = () => {
  useEffect(() => {
    document.addEventListener('contextmenu', function (event) {
      // Предотвращаем стандартное поведение (вызов контекстного меню)
      event.preventDefault()
    })
    return document.removeEventListener('contextmenu', function (event) {
      event.preventDefault()
    })
  }, [])
  return (
    <div className={s.app_container}>
      <Routes>
        <Route path="/*" element={<RootNavigation />} />
      </Routes>
    </div>
  )
}

export default React.memo(AppView)
