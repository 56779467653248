import React, { useMemo } from 'react'
import { ReactComponent as ArrowSvg } from 'images/right_arrow.svg'
import { ReactComponent as RepeatSvg } from 'images/repeat_icon.svg'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'

import s from './Button.module.scss'

type Props = {
  text: string
  withArrow?: boolean
  withRepeatIcon?: boolean
  to?: string
  onClick?: (e: any) => void
  color: 'red' | 'white'
  containerClassName?: string
}

const Button = ({
  text,
  withArrow,
  to,
  onClick,
  color,
  containerClassName,
  withRepeatIcon,
}: Props) => {
  const btnClassNames = useMemo(() => {
    switch (color) {
      case 'red':
        return {
          container: s.red_btn,
          text: s.red_btn_txt,
          arrow: s.red_btn_arrow,
        }
      default:
        return {
          container: s.white_btn,
          text: s.white_btn_txt,
          arrow: s.white_btn_arrow,
        }
    }
  }, [color])

  return to ? (
    <NavLink className={cn(s.button, btnClassNames.container, containerClassName || '')} to={to}>
      <div className={cn(s.text, btnClassNames.text)}>{text}</div>
      {withArrow && (
        <div className={cn(s.arrow, btnClassNames.arrow)}>
          <ArrowSvg />
        </div>
      )}
    </NavLink>
  ) : (
    <div
      className={cn(s.button, btnClassNames.container, containerClassName || '')}
      onClick={onClick}
    >
      <div className={cn(s.text, btnClassNames.text)}>{text}</div>
      {withArrow && (
        <div className={cn(s.arrow, btnClassNames.arrow)}>
          <ArrowSvg />
        </div>
      )}
      {withRepeatIcon && (
        <div className={cn(s.repeat_icon, btnClassNames.arrow)}>
          <RepeatSvg />
        </div>
      )}
    </div>
  )
}

export default React.memo(Button)
