import React from 'react'
import { ReactComponent as BackSvg } from 'images/back_black_arrow.svg'
import { useNavigate } from 'react-router'

import s from './GoBackBtn.module.scss'

type Props = {
  seconds: number
}

const GoBackBtn = ({ seconds }: Props) => {
  const navigate = useNavigate()
  return (
    <div className={s.container}>
      <div className={s.btn} onClick={() => navigate('/', { replace: true })}>
        <BackSvg />
      </div>
      <div className={s.timer}>{seconds}</div>
    </div>
  )
}

export default React.memo(GoBackBtn)
