import { BrowserRouter, HashRouter } from 'react-router-dom'

import AppView from './AppView'
import 'swiper/css'
import './app.scss'

function App() {
  return (
    <HashRouter>
      <AppView />
    </HashRouter>
  )
}

export default App
