import React, { useCallback } from 'react'
import { Button } from 'application/presentation/common/uiComponents'
import { useNavigate } from 'react-router'

import s from '../ResultPage.module.scss'
import GoBackBtn from '../components/GoBackBtn'
import { useTimer } from '../useTimer'

const TimerEndStage = () => {
  const navigate = useNavigate()
  const { seconds } = useTimer(180)

  const onBtnClick = useCallback(() => {
    return navigate(-1)
  }, [navigate])

  const repeatBtnClick = useCallback(() => {
    localStorage.removeItem('questions')
    localStorage.removeItem('answersData')
    navigate(-1)
  }, [navigate])

  return (
    <>
      <GoBackBtn seconds={seconds} />
      <div className={s.stage_container}>
        <div className={s.title}>Продолжаем игру?</div>
        <div className={s.subtitle}>
          Мы заметили что вы не отвечаете
          <br />в течение 40 секунд.
        </div>
        <Button
          text="ПРОДОЛЖИТЬ"
          withArrow
          color="red"
          containerClassName={s.btn_container}
          onClick={onBtnClick}
        />
        <Button
          text="НАЧАТЬ ЗАНОВО"
          color="red"
          containerClassName={s.repeat_btn_container}
          onClick={repeatBtnClick}
          withRepeatIcon
        />
        <div className={s.notice}>
          Через {seconds} секунд раунд
          <br />
          автоматически закончится.
        </div>
      </div>
    </>
  )
}

export default React.memo(TimerEndStage)
