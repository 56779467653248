import React, { useState, useCallback, useRef, useEffect } from 'react'
import { Answer, Question } from 'application/domain/entity/question/Question'
import cn from 'classnames'
import fingerdown from 'images/finger_down.png'
import fingerup from 'images/finger_up.png'

import s from './Answers.module.scss'

type Props = {
  data: Question
  timerRef: any
  onAnswerClick: (item: Answer) => void
  setSeconds: Function
}

const Answers = ({ data, timerRef, onAnswerClick, setSeconds }: Props) => {
  const answerTimer = useRef<any>()
  const answerItemsTimer = useRef<any>()
  const [lastSelectedItem, setLastSelectedItem] = useState<number>(-1)
  const [showResults, setShowResults] = useState<boolean>(false)
  const [hidden, setHidden] = useState<boolean>(false)
  const onAnswerClickHandler = useCallback(
    (item: Answer) => {
      setLastSelectedItem(item.id)
      setShowResults(true)
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
      if (answerTimer.current) {
        clearTimeout(answerTimer.current)
      }
      if (answerItemsTimer.current) {
        clearTimeout(answerItemsTimer.current)
      }
      answerItemsTimer.current = setTimeout(() => setHidden(true), 2500)
      answerTimer.current = setTimeout(() => {
        setLastSelectedItem(-1)
        setShowResults(false)
        onAnswerClick(item)
        // setSeconds(20)
      }, 3000)
    },
    [onAnswerClick, timerRef],
  )

  useEffect(() => {
    setHidden(false)
  }, [data])

  return (
    <div className={s.answers}>
      {data.answers.map((item, index) => {
        const notCorrect = item.id === lastSelectedItem && !item.correct
        const isCorrect = item.id === lastSelectedItem && item.correct
        return (
          <div
            className={cn(s.answer_out_container, { [s.hidden]: hidden })}
            key={`answer_item_${item.id}`}
          >
            <img src={fingerdown} className={cn(s.finger_down, { [s.show]: notCorrect })} />
            <div className={cn(s.fingerUps, { [s.show]: isCorrect && showResults })}>
              <img src={fingerup} className={s.finger1} />
              <img src={fingerup} className={s.finger2} />
            </div>
            <div
              className={cn(s.answer, {
                [s.correct]: isCorrect,
                [s.not_correct]: notCorrect,
                [s.show_results]: showResults,
                [s.clicked]: lastSelectedItem === item.id,
              })}
              onClick={() => onAnswerClickHandler(item)}
              key={`item_${data.id}_${item.id}`}
            >
              <div className={s.num_container}>
                <div className={s.num}>{index + 1}</div>
              </div>
              <div className={s.txt}>{item.title}</div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default React.memo(Answers)
