import { useState, useRef, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router'

export const useTimer = (deadline?: number) => {
  const navigate = useNavigate()
  const timer = useRef<any>()
  const [seconds, setSeconds] = useState<number>(deadline || 20)

  const startTimer = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current)
    }
    timer.current = setTimeout(() => {
      if (seconds > 0) {
        setSeconds((prev) => prev - 1)
      } else {
        navigate('/', { replace: true })
      }
    }, 1000)
  }, [seconds, navigate])

  useEffect(() => {
    startTimer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds])
  return {
    seconds,
  }
}
